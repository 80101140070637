<template>
  <div class="projects-modify">
    <v-container class="projects-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <div class="projects-tabs">
        <form-builder
          v-if="!isLoading"
          :schema="schema"
          :initial-data="initialData"
          is-tabs
          :tabs="tabs"
          :active-tab="tab"
          @submit="submit"
          @change-tab="changeTab"
        >
          <template #footer="{ valid }">
            <v-row class="footer-row mt-4" :class="{ valid: checkValid(valid) }">
              <v-col>
                <v-btn v-if="tab >= 1" color="secondary" block class="primary--text" @click="changeTab(tab - 1)">
                  {{ $t('button.back') }}
                </v-btn>
                <v-btn v-else color="secondary" block class="primary--text" :to="backRoute">{{
                  $t('button.cancel')
                }}</v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="tab < tabs.length - 1" type="button" color="primary" block @click.prevent="changeTab">
                  {{ $t('button.continue') }}
                </v-btn>
                <v-btn v-else :disabled="!checkValid(valid)" type="submit" color="primary" block>{{
                  submitButtonText
                }}</v-btn>
              </v-col>
            </v-row>
          </template>
        </form-builder>
      </div>
    </v-container>
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';
import { normalizeProject } from '@/services/serializations';

// Schema
import { detailsSchema, teamSchema, notificationsSchema } from '@/schemas/project.schema';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Constants
import { PROJECTS, PROJECTS_DETAILED } from '@/constants/routes';
import { TAB_DETAILS, TAB_TEAM, TAB_NOTIFICATIONS } from '@/constants/tabs';
import { CREATE_PROJECT, EDIT_PROJECT } from '@/constants/analyticsActions';

export default {
  name: 'ProjectsEdit',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData([...detailsSchema, ...teamSchema, ...notificationsSchema]),
      schemaList: [detailsSchema, teamSchema, notificationsSchema],
      currentValid: false,
      tab: 0,
      tabs: [
        {
          label: 'tab.details',
          tab: TAB_DETAILS,
          valid: false,
        },
        {
          label: 'tab.team',
          tab: TAB_TEAM,
          valid: false,
        },
        {
          label: 'tab.notifications',
          tab: TAB_NOTIFICATIONS,
          valid: false,
        },
      ],
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_project' : 'button.add_project');
    },

    backRoute() {
      return this.isEdit
        ? { name: PROJECTS_DETAILED, query: { id: this.$route.params.id } }
        : { name: PROJECTS, query: { page: this.$route.query?.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('projects.edit_project') : this.$t('projects.add_project');
    },

    schema() {
      return this.schemaList[this.tab];
    },

    disabledSubmit() {
      return !(this.detailsValid && this.notificationsValid && this.tab === 1);
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      realtyService
        .getProjectById(this.$route.params.id)
        .then(project => {
          this.initialData = normalizeProject(project);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      const notificationSettings = data.notificationSettings
        ? {
            at: data.at.value,
            repeatCount: data.repeatCount,
            repeatInterval: data.repeatInterval.value,
            recipients: data.recipients,
            employees: this.formatPerformers(data.employees),
          }
        : undefined;

      const bodyRequest = this.createBodyRequest(data);

      const saveProject = this.isEdit ? realtyService.updateProject : realtyService.createProject;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.isLoading = true;
      analyticsService.track(this.isEdit ? EDIT_PROJECT : CREATE_PROJECT);

      saveProject(bodyRequest, notificationSettings, data.id)
        .then(project => {
          const message = this.isEdit ? this.$t('project.project_edited') : this.$t('project.project_added');
          notificationService.success(message, 2000);

          this.$router.push({ name: PROJECTS_DETAILED, params: { id: project.id || this.$route.params.id } });
        })
        .catch(error => {
          this.initialData = { ...data };

          const keys = Object.keys(error?.response?.data);

          if (keys.length) {
            for (let i = 0; i < keys.length; i += 1) {
              const key = keys[i];
              const errorText = this.getErrorText(error.response.data, key);
              this.$options.notificationItem = notificationService.error(`${key}: ${errorText}`);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createBodyRequest(data) {
      return {
        ...data,
        currency: data.currency?.id || undefined,
        community: data.community || undefined,
        developer: data.developer || undefined,
        landNumber: data.landNumber || undefined,
        makaniNumber: data.makaniNumber || undefined,
        parcelNumber: data.parcelNumber || undefined,
        plotNumber: data.plotNumber || undefined,
        coverImage: data.coverImage[0]?.backgroundFileId || null,
        projectType: data.projectType.value,
        country: data.country.value,
        state: data.state || undefined,
        city: data.city || undefined,
        postal: data.postal || undefined,
        companyAddress: data.companyAddress || undefined,
        location: data.location || undefined,
        roles: this.formatRoles(data.roles),
      };
    },
    getErrorText(data, key) {
      return Array.isArray(data[key]) ? data[key][0] : data[key];
    },
    formatPerformers(employees) {
      return employees.include.map(item => item.id);
    },
    formatRoles(roles) {
      return roles.map(role => {
        return {
          role: role.role.id,
          employees: {
            all: role.employees.all,
            include: role.employees.include.map(employ => employ.id),
          },
        };
      });
    },
    checkValid(valid) {
      this.tabs[this.tab].valid = valid;
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (!this.tabs[i].valid) {
          return false;
        }
      }
      return true;
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  notificationItem: null,
  detailsSchema,
  teamSchema,
  notificationsSchema,
};
</script>

<style lang="scss">
.projects-modify {
  &__container {
    max-width: 988px !important;
  }
}

.projects-tabs {
  .v-tabs {
    margin-bottom: 41px;
  }

  .v-tab {
    padding: 0;
    min-width: 0;

    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: none;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .v-tab + .v-tab {
    margin-left: 24px;
  }

  .v-tabs-bar {
    height: 36px;
  }

  .footer-row {
    display: inline-flex;

    .col {
      width: 266px;
    }
  }
}
</style>
